<template>
	<BaseLayout>
		<Toolbar @clickLeft="$router.push('/account')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ $t('invite.inviteFriends') }}</p>
		</Toolbar>
		<div class="mt-5 p-5">
			<!-- Title -->
			<p class="mb-3 text-xl font-bold">{{ $t('invite.bonusPoints') }}</p>
			<!-- Invite Info -->
			<div class="mb-5 flex justify-around rounded-xl bg-light-blue py-1">
				<div class="flex flex-col items-center py-2 text-gray-500">
					<p class="text-3xl text-primary">{{ customer?.campaignCount }}{{ $t('invite.person') }}</p>
					<p class="py-1 text-xs">{{ $t('invite.invited') }}</p>
				</div>
				<div class="flex flex-col items-center py-2 text-primary">
					<p class="text-3xl text-primary">{{ customer?.campaignPoints }}P</p>
					<p class="py-1 text-xs text-gray-500">{{ $t('invite.pointEarned') }}</p>
				</div>
			</div>
			<!-- Invite Code -->
			<div class="mb-5 flex justify-center rounded-xl bg-primary py-1 text-white" @click="copyInviteCode">
				<div class="flex flex-col items-center py-2">
					<p class="text-3xl">{{ customer?.campaignCode }}</p>
					<p class="py-1 text-xs">{{ $t('invite.personalInviteCode') }}</p>
				</div>
			</div>

			<!-- Add new invite code -->
			<div class="mb-2 flex">
				<p class="text-xl font-bold">{{ $t('invite.enterInviteCode') }}</p>
			</div>
			<div class="mb-5 rounded-xl bg-light-blue px-4">
				<input v-model="code" class="text-md h-16 w-full bg-transparent placeholder-muted" :placeholder="$t('invite.enterInviteCode')" :disabled="customer?.referralCode" />
			</div>

			<!-- Submit invite code -->
			<Footer class="-mx-5">
				<Button :class="{ 'bg-muted': customer?.referralCode }" :disabled="isLoading || customer?.referralCode" @click="submit">{{ customer?.referralCode ? $t('invite.submittedInviteCode') : $t('invite.submitInviteCode') }}</Button>
			</Footer>
		</div>
	</BaseLayout>

	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
	<Toast v-if="success" @close="success = false" class="bg-green-500">{{ $t('invite.copyInviteCode') }}</Toast>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { Share } from '@capacitor/share'
import { useCustomerStore } from '@/store/customer'
import { useI18n } from 'vue-i18n'
import { customerApi } from 'gox-sdk'

export default {
	setup() {
		const store = useStore()
		const { t } = useI18n({ useScope: 'global' })
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const customer = currentCustomer
		// const customer = computed(() => store.state.customer)
		const code = ref(customer.value?.referralCode ? customer.value?.referralCode : '')

		const success = ref(false)
		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		async function copyInviteCode() {
			await Share.share({
				// title: 'See cool stuff',
				text: `${t('account.inviteMessage1')}${customer?.value.campaignCode}${t('account.inviteMessage2')}`,
				url: 'http://myjetmall.com',
				// dialogTitle: 'Share with buddies',
			})
			navigator.clipboard.writeText(customer?.value.campaignCode)
			success.value = true
		}

		async function submit() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await customerApi.referCustomer(customer.value.id, { code: code.value })
				// await store.dispatch('redeemInviteCode', { code: code.value })
				// await store.dispatch('getCustomer')
				code.value = customer.value?.referralCode
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			customer,
			code,
			success,
			error,
			isLoading,
			copyInviteCode,
			submit,
		}
	},
}
</script>
